import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Input, Button, Alert, Card } from 'antd';
import Ink from 'react-ink';

import './login.css';

import {
	userLogin
} from '../../modules/user';

import { MailOutlined, LockOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

class LoginCard extends Component {

	formRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			message: null
		}
	}

	handleSubmit = (values) => {
		const { userSessionTimeout } = this.props;
		this.setState({ loading: true });
		this.props.userLogin(values).then(
			(res) => {
				this.setState({ loading: false, message: null });
				if (this.formRef.current) {
					this.formRef.current.resetFields();
				}
				if(!userSessionTimeout){
					window.parent.location = `${window.parent.location.origin}`;
				}
			},
			(err) => {
				this.setState({ loading: false, message: `Please check Username/Password and try again.` });
			}
		);
	}

	render() {
		const { loading, message } = this.state;
		return (
				<Card className="loginHolder">
					<h3 className="title"><LockOutlined /> Sign In</h3>
					<Form ref={this.formRef} name="myForm" onFinish={this.handleSubmit} className="login-form">
						<div className="contentHolder">
							{(message) ? <Alert message={message} type="error" style={{ marginBottom: '20px' }} showIcon /> : ""}
							<FormItem name='email' rules={[{ type: 'email', message: 'Please input a valid E-mail!' },
							{ required: true, message: 'Please input your username!' }]}>
								<Input style={{ borderLeft: "0px", borderRight: "0px", borderTop: "0px" }} size="large" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
							</FormItem>
							<FormItem name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
								<Input style={{ borderLeft: "0px", borderRight: "0px", borderTop: "0px" }} size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
							</FormItem>
						</div>
						<div className="btnHolder">
							{
								(loading) ?
									<Button size="large" htmlType="submit" disabled><Ink />Log in </Button>
									:
									<Button size="large" htmlType="submit"><Ink />Sign In </Button>
							}
						</div>
					</Form>
				</Card>
		);
	}
}

const mapStateToProps = state => ({
	userAuthenticated: state.user.userAuthenticated
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			userLogin
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard);
