const axios_1 = require("axios");
// const ua = `volc-sdk-nodejs/v1.19.0`;
async function request(url, reqInfo) {
    const { headers = {} } = reqInfo;
    const reqOption = Object.assign(Object.assign({ url: url.trim(), timeout: 10000 }, reqInfo), { proxy: process.env.VOLC_PROXY_PORT
            ? {
                protocol: "http",
                host: process.env.VOLC_PROXY_HOST || "127.0.0.1",
                port: +process.env.VOLC_PROXY_PORT,
            }
            : undefined, headers: Object.assign(Object.assign({ "Content-Type": "application/x-www-form-urlencoded" }, headers)), validateStatus: null });


    // console.log("fetch begin. options: %j", JSON.stringify(reqOption));
    const res = await (0, axios_1.default)(reqOption);
    const body = res.data;
    // console.log("fetch end. headers: %j response: %j", res.headers, body);
    return body;
}
export default request;

