import api from './api/api';

export const GET_ACCOUNT = 'ACCOUNT/GET';
export const LIST_ACCOUNT = 'ACCOUNT/LIST';
export const UPDATE_ACCOUNT = 'ACCOUNT/UDPATE';


export const ListRoles = 'USER/ListRoles';
export const CreateServiceLinkedRole = 'USER/CreateServiceLinkedRole';

export const ListUsers = 'USER/ListUsers';
export const DeleteUser = 'USER/DeleteUser';
export const CreateUser = 'USER/CreateUser';
export const UpdateUser = 'USER/UpdateUser';
export const ListBillOverviewByProdOwner = 'USER/ListBillOverviewByProdOwner';
export const ListBillDetailOwner = 'USER/ListBillDetailOwner';
export const CreateLoginProfile = 'USER/CreateLoginProfile';
export const UpdateLoginProfile = 'USER/UpdateLoginProfile';
export const UpdateUserPolicies = 'USER/UpdateUserPolicies';
export const EnableConsoleLogin = 'USER/EnableConsoleLogin';
export const CreateFinancialRelation = 'USER/CreateFinancialRelation';

const initialState = {
	organizationalUnitAccount: {},
	users: [],
	billDetailOwner: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ACCOUNT:
			return {
				...state,
				organizationalUnitAccount: action.payload,
				users: []
			};
    case ListRoles:
      return {
        ...state,
        // users: action.payload
      };
    case CreateServiceLinkedRole:
      return {
        ...state,
        // users: action.payload
      };
    case ListUsers:
      return {
        ...state,
        users: action.payload
      };
    case DeleteUser:
      return {
        ...state,
        deleteUser: action.payload
      };
    case CreateUser:
      return {
        ...state,
        createUser: action.payload
      };
    case UpdateUser:
      return {
        ...state,
        updateUser: action.payload
      };
    case CreateLoginProfile:
      return {
        ...state,
        createLoginProfile: action.payload
      };
    case UpdateLoginProfile:
      return {
        ...state,
        updateLoginProfile: action.payload
      };
    case UpdateUserPolicies:
      return {
        ...state,
        updateUserPolicies: action.payload
      };
    case ListBillOverviewByProdOwner:
      return {
        ...state,
        billOverviewByProdOwner: action.payload
      };
    case ListBillDetailOwner:
      return {
        ...state,
        billDetailOwner: Array.isArray(action.payload)?action.payload:[]
      };
		default:
			return state;
	}
};


export const updateAccountActive = (accountId) => {
	return dispatch => {
		return api.patch('/account/'+accountId, {status:'Active'}).then(data => {
			dispatch({
				type: UPDATE_ACCOUNT,
				payload: data
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};


export const getAccountByOrgUnitId = (payload) => {
	return dispatch => {
		return api.get('/account?OrgUnitId='+payload).then(data => {
			dispatch({
				type: GET_ACCOUNT,
				payload: data
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};


export const getAccountByParentId = (payload) => {
	return dispatch => {
		return api.get('/account?ParentId='+payload).then(data => {
			dispatch({
				type: LIST_ACCOUNT,
				payload: data
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const listRoles = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/ListRoles',{...payload, ...credentials}).then(data => {
      dispatch({
        type: ListRoles,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const createServiceLinkedRole = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/CreateServiceLinkedRole',{...payload, ...credentials}).then(data => {
      dispatch({
        type: CreateServiceLinkedRole,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const listUsers = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/ListUsers',{...payload, ...credentials}).then(data => {
      dispatch({
        type: ListUsers,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const deleteUser = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/DeleteUser',{...payload, ...credentials}).then(data => {
      dispatch({
        type: DeleteUser,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const createUser = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/CreateUser',{...payload, ...credentials}).then(data => {
      dispatch({
        type: CreateUser,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};


export const updateUser = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/UpdateUser',{...payload, ...credentials}).then(data => {
      dispatch({
        type: UpdateUser,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const createLoginProfile = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/CreateLoginProfile',{...payload, ...credentials}).then(data => {
      dispatch({
        type: CreateLoginProfile,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const updateLoginProfile = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/UpdateLoginProfile',{...payload, ...credentials}).then(data => {
      dispatch({
        type: UpdateLoginProfile,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const updateUserPolicies = (payload, credentials) => {
	return dispatch => {
		return api.post('/bpapi/UpdateUserPolicies',{...payload, ...credentials}).then(data => {
      dispatch({
        type: UpdateUserPolicies,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const listBillOverviewByProdOwner = (payload) => {
	return dispatch => {
		return api.post('/bpapi/ListBillOverviewByProdOwner',{...payload}).then(data => {
      dispatch({
        type: ListBillOverviewByProdOwner,
        payload: data
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const listBillDetailOwner = (payload) => {
	return dispatch => {
		return api.post('/bpapi/ListBillDetailOwner',{...payload}).then(data => {
      dispatch({
        type: ListBillDetailOwner,
        payload: data
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};


export const enableConsoleLogin = (AccountId) => {
	return dispatch => {
		return api.post('/bpapi/EnableConsoleLogin',{AccountId,  "Password": "Omg123456"}).then(data => {
      dispatch({
        type: EnableConsoleLogin,
        payload: data
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};


export const createFinancialRelation = (payload) => {
	return dispatch => {
		return api.post('/bpapi/CreateFinancialRelation',payload).then(data => {
      dispatch({
        type: CreateFinancialRelation,
        payload: data
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};
