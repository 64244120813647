import axios from 'axios';
import config from '../../config';

import tokenApi from './tokenApi';

const instance = axios.create({
	baseURL: config.api,
	headers: {
		'Content-Type': 'application/json'
	}
});

instance.interceptors.response.use(
	response => responseSuccessHandler(response),
	error => responseErrorHandler(error)
);

const responseSuccessHandler = response => {
	return response;
};

const responseErrorHandler = error => {
	if (!error.response || error.response.status === 401) {
		const event = document.createEvent("Event");
		event.initEvent("SessionExpired", true, true);
		document.dispatchEvent(event);
	}
	return Promise.reject(error);
}

class Api {
	static get(path) {
		instance.defaults.headers.common['Authorization'] = tokenApi.GetToken();
		return instance.get(path)
			.then(function (response) {
				return response.data;
			})
			.catch(function (error) {
				throw (error);
			});
	}
	static post(path, payload) {
		instance.defaults.headers.common['Authorization'] = tokenApi.GetToken();
		return instance.post(path, payload)
			.then(function (response) {
				return response.data;
			})
			.catch(function (error) {
				throw (error);
			});
	}
	static patch(path, payload) {
		instance.defaults.headers.common['Authorization'] = tokenApi.GetToken();
		return instance.patch(path, payload)
			.then(function (response) {
				return response.data;
			})
			.catch(function (error) {
				throw (error);
			});
	}
	static delete(path, payload) {
		instance.defaults.headers.common['Authorization'] = tokenApi.GetToken();
		return instance.delete(path, payload)
			.then(function (response) {
				return response.data;
			})
			.catch(function (error) {
				throw (error);
			});
	}
}


export default Api;
