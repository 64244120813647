const fs_1 = require("fs");
const path_1 = require("path");
exports.getDefaultOption = () => {
    const defaultOptions = {
        host: "open.volcengineapi.com",
        region: "cn-north-1",
        protocol: "https:",
        // Read aksk by environment variables
        accessKeyId: process.env.VOLC_ACCESSKEY,
        secretKey: process.env.VOLC_SECRETKEY,
    };
    try {
        // Read aksk from ~/.volc/config. Priority is lower than environment variables
        if (process.env.HOME && !(defaultOptions.accessKeyId && defaultOptions.secretKey)) {
            const homeConfigPath = path_1.default.resolve(process.env.HOME, ".volc/config");
            if (fs_1.default.existsSync(homeConfigPath)) {
                const configData = JSON.parse(fs_1.default.readFileSync(homeConfigPath, { encoding: "utf-8" }));
                if (!defaultOptions.accessKeyId && configData.VOLC_ACCESSKEY) {
                    defaultOptions.accessKeyId = configData.VOLC_ACCESSKEY;
                }
                if (!defaultOptions.secretKey && configData.VOLC_SECRETKEY) {
                    defaultOptions.secretKey = configData.VOLC_SECRETKEY;
                }
            }
        }
    }
    catch (_a) { }
    return defaultOptions;
};
