// exports.base64 = exports.CreateTempAKSK = exports.CreateInnerToken = void 0;
const uuid_1 = require("uuid");
const crypto_1 = require("crypto");
const LETTERS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const base64 = (exports.base64 = (str) => {
    return Buffer.from(str).toString("base64");
});

function randStringRunes(n) {
    const arr = [];
    for (let i = 0; i < n; i++) {
        arr.push(LETTERS[Math.floor(Math.random() * 32)]);
    }
    return arr.join("");
}
function GenerateAccessKeyId(prefix) {
    const uid = (0, uuid_1.v4)();
    const uidBase64 = base64(uid.replace(/-/g, "")).replace(/=|\/|\+|-/g, "");
    return prefix + uidBase64;
}
function toBuffer(x) {
    if (x instanceof Buffer)
        return x;
    return Buffer.from(x, "utf8");
}
function ZeroPadding(data, blockSize) {
    const padding = blockSize - (data.length % blockSize);
    if (padding === 0) {
        return data;
    }
    const buff = Buffer.alloc(padding);
    const totalLength = data.length + padding;
    return Buffer.concat([data, buff], totalLength);
}
function AesEncryptCBCWithBase64(data, key) {
    if ([16, 24, 32].indexOf(key.length) < 0) {
        throw new Error("aes cbc block size must be 16|24|32 bytes");
    }
    data = toBuffer(data);
    key = toBuffer(key);
    const blocksize = key.length;
    const iv = key;
    const cipher = crypto_1.default.createCipheriv(`aes-128-cbc`, key, iv);
    const finaldata = ZeroPadding(data, blocksize);
    let encrypted = cipher.update(finaldata);
    const final = cipher.final();
    encrypted = Buffer.concat([encrypted, final], encrypted.length + final.length);
    return Buffer.from(encrypted).toString("base64");
}
function GenerateSecretKey() {
    const randomString = randStringRunes(32);
    return AesEncryptCBCWithBase64(randomString, "volcenginenodejs");
}
exports.CreateTempAKSK = () => {
    return {
        AccessKeyId: GenerateAccessKeyId("AKTP"),
        SecretAccessKey: GenerateSecretKey(),
    };
};
function hmac(type, str, secret) {
    return crypto_1.default.createHmac(type, secret).update(str).digest("hex");
}
function hash(type, str) {
    return crypto_1.default.createHash(type).update(str).digest();
}
exports.CreateInnerToken = (config, sts, inlinePolicy, t) => {
    const key = hash("md5", config.secretKey);
    let SignedSecretAccessKey = AesEncryptCBCWithBase64(sts.SecretAccessKey, key);
    SignedSecretAccessKey = SignedSecretAccessKey.slice(0, -24);
    const signStr = Object.values({
        LTAccessKeyId: config.accessKeyId,
        AccessKeyId: sts.AccessKeyId,
        ExpiredTime: t,
        SignedSecretAccessKey,
        PolicyString: inlinePolicy ? JSON.stringify(inlinePolicy) : "",
    }).join("|");
    return {
        LTAccessKeyId: config.accessKeyId,
        AccessKeyId: sts.AccessKeyId,
        SignedSecretAccessKey,
        ExpiredTime: t,
        PolicyString: inlinePolicy ? JSON.stringify(inlinePolicy) : "",
        Signature: hmac("sha256", signStr, key),
    };
};
