import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Form, Input, Button, message } from 'antd';
import Ink from 'react-ink';

import {
	createUser, updateUser, createLoginProfile, updateLoginProfile, updateUserPolicies
} from '../../../modules/api';

import { UserOutlined, UserAddOutlined, LockOutlined, ArrowRightOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

class AddUserModal extends Component {
	formRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}


	async componentDidMount() {
		const { data } = this.props;
		const initialValues = {
			UserName: (this.props.data && this.props.data.UserName) ? this.props.data.UserName : "",
			DisplayName: (this.props.data && this.props.data.DisplayName) ? this.props.data.DisplayName : "",
			Description:  (this.props.data && this.props.data.Description) ? this.props.data.Description : "",
			Password: "",
			confirmPassword: "",
		};
		if (this.formRef.current && data) {
			this.formRef.current.setFieldsValue(initialValues);
		}
	}


	async componentDidUpdate(prevProps) {
		const { data } = this.props;
		if(JSON.stringify(prevProps.data) !== JSON.stringify(data)){
			const initialValues = {
				UserName: (this.props.data && this.props.data.UserName) ? this.props.data.UserName : "",
				DisplayName: (this.props.data && this.props.data.DisplayName) ? this.props.data.DisplayName : "",
				Description:  (this.props.data && this.props.data.Description) ? this.props.data.Description : "",
				Password: "",
				confirmPassword: "",
			};

			if (this.formRef.current && data) {
				this.formRef.current.setFieldsValue(initialValues);
			}
		}
	}

	handleSubmit = async (values) => {
		const { credentials } = this.props;

		this.setState({ loading: true });

		if (!this.props.data && values.Password.length < 8) {
			this.formRef.current.setFields({
				Password: {
					value: values.Password,
					errors: [new Error('Password is too short (min character length is 8)')],
				}
			});
			this.setState({ loading: false });
		} else {
			if (!this.props.data) {
				this.props.createUser(values, credentials).then(
					(res) => {
						if(res.ResponseMetadata && res.ResponseMetadata.Error){
							message.error(res.ResponseMetadata.Error.Message);
							this.setState({ loading: false });
						}else{
							this.props.createLoginProfile({...values, LoginAllowed:true, PasswordResetRequired:true}, credentials).then(
								(data)=>{
									this.props.updateUserPolicies({...values, PolicyGroup:['CDN']}, credentials);

/*
									if(data.ResponseMetadata && data.ResponseMetadata.Error){
										message.error(data.ResponseMetadata.Error.Message);
										this.setState({ loading: false });
									}else{
									}
*/
								message.success('User successfully added!');
								if (this.formRef.current) { this.formRef.current.resetFields(); }
								this.setState({ loading: false, message: null }, ()=>{
									this.onClose();
								});
							});
						}
					},
					(err) => {
						this.setState({ loading: false, message: `Can't get through. Please make sure email is unique or contact the administrator.` });
					}
				);
			} else {
				values.UserName = this.props.data.UserName;
				values.NewUserName = values.UserName;
				values.NewDisplayName = values.DisplayName;
				values.NewDescription = values.Description;
				await this.props.updateUserPolicies({...values, PolicyGroup:['CDN']}, credentials);
				this.props.updateUser(values, credentials).then(
					(res) => {
						if(res.ResponseMetadata && res.ResponseMetadata.Error){
							message.error(res.ResponseMetadata.Error.Message);
						}else{
							if(values.Password !== ""){
								this.props.updateLoginProfile({UserName:values.UserName, Password:values.Password}, credentials).then((data)=>{
									message.success('User successfully updated!');
									if (this.formRef.current) { this.formRef.current.resetFields(); }
									this.setState({ loading: false, message: null }, ()=>{
										this.onClose();
									});
								});
							}else{
								message.success('User successfully updated!');
								if (this.formRef.current) { this.formRef.current.resetFields(); }
								this.setState({ loading: false, message: null }, ()=>{
									this.onClose();
								});
							}
						}
					},
					(err) => {
						this.setState({ loading: false, message: `Can't get through. Please make sure email is unique or contact the administrator.` });
					}
				);
			}
		}
	}

	handleConfirmBlur = (e) => {
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	}

	compareToFirstPassword = (rule, value, callback) => {

		const form = this.formRef.current;

		if (!(/^([a-zA-Z0-9]{8,32})$/.test(value) && /[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value))) {
			callback('Must be 8-32 characters long contains lowercases, uppercases and numbers');
		} else if (value && value !== form.getFieldValue('Password')) {
			callback('Two passwords that you enter is inconsistent!');
		} else {
			callback();
		}
	}

	validateToNextPassword = (rule, value, callback) => {

		const form = this.formRef.current;

		if (!(/^([a-zA-Z0-9]{8,32})$/.test(value) && /[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value))) {
			callback('Must be 8-32 characters long contains lowercases, uppercases and numbers');
		} else if (value && this.state.confirmDirty) {
			form.validateFields(['confirmPassword'], { force: true });
		}
		callback();
	}


	onClose() {
		this.setState({
			message: null
		});
		if (this.formRef.current) { this.formRef.current.resetFields(); }
		this.props.toggleAddUserModal();
	}

	render() {
		const { loading } = this.state;

		const formItemLayout = {
			labelCol: { span: 8 },
			wrapperCol: { span: 14 },
		};

		const initialValues = {
			UserName: (this.props.data && this.props.data.UserName) ? this.props.data.UserName : "",
			DisplayName: (this.props.data && this.props.data.DisplayName) ? this.props.data.DisplayName : "",
			Description:  (this.props.data && this.props.data.Description) ? this.props.data.Description : "",
			Password: "",
			confirmPassword: "",
		};

		return (
			<Modal
				title={(this.props.data) ? <span><UserOutlined /> User Details</span> : <span><UserAddOutlined /> Add User</span>}
				visible={this.props.show}
				onCancel={() => this.onClose()}
				footer={[
					<Button key="submit" form="myUserForm" type="primary" htmlType="submit" disabled={loading} ><Ink /><ArrowRightOutlined /> Submit</Button>,
					<Button key="back" onClick={() => this.onClose()} disabled={loading}>Cancel</Button>
				]}
				maskClosable={false}
				closable={(loading) ? false : true}
				>
				<Form className="userForm" ref={this.formRef} name="myUserForm" onFinish={this.handleSubmit} initialValues={initialValues} >
					<FormItem  {...formItemLayout} label="Contact Name" name="UserName" rules={[{ required: true, message: 'Please input User Name' }]}>
						<Input prefix={<UserOutlined />}  placeholder="Unique User Name"/>
					</FormItem>
					<FormItem  {...formItemLayout} label="Display Name" name="DisplayName" rules={[{ required: true, message: 'Please input Display Name' }]}>
						<Input prefix={<UserOutlined />}  placeholder="Display Name" />
					</FormItem>
					<FormItem  {...formItemLayout} label="Description" name="Description" rules={[]}>
						<Input placeholder="Description" />
					</FormItem>
					<FormItem  {...formItemLayout} label="Password" name="Password" rules={[{ required: (!this.props.data), message: 'Please input your Password!' },
					{ validator: this.validateToNextPassword }]}>
						<Input autoComplete="new-password" prefix={<LockOutlined />} type="password" placeholder="Password" />
					</FormItem>
					<FormItem  {...formItemLayout} label="Confirm Password" name="confirmPassword" rules={[{ required: (!this.props.data), message: 'Please confirm your password!' },
					{ validator: this.compareToFirstPassword }]}>
						<Input prefix={<LockOutlined/>} type="password" placeholder="Confirm Password"  />
					</FormItem>
				</Form>
			</Modal>
		);
	}
}


const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createUser,
			updateUser,
			createLoginProfile,
			updateLoginProfile,
			updateUserPolicies
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
