import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import store from '../../store'
// import { Link } from 'react-router-dom';
import { Menu, Button, Tooltip, FloatButton } from 'antd';
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	ApartmentOutlined,
	ProductOutlined,
	LogoutOutlined,
	AppstoreAddOutlined
} from '@ant-design/icons';
import './sidenav.css';
import { version } from '../../config';

import {
	userList,
	userLogout,
	userSideNav,
	appTitleUpdate
} from '../../modules/user';

import avatar from '../../static/avatar.png';

// import userImg from '../static/logo_dark.png';
import userImgMin from '../../static/logo.png';


const menuIndex = [
	{index:'/', title:'Organisation', showOrg:true, showTime:true},
	{index:'/user', title:'Account Management', showOrg:true, showTime:true},
	{index:'/organization/new', title:'Create Reseller Organisation', showOrg:true, showTime:true},
];

class SideNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userModal: false,
			openKeys: [],
			accounts: []
		}
	}

  async componentDidMount() {
		const { location, userProfile } = this.props;
		if(userProfile && userProfile.user && userProfile.user.superadmin){
			let accounts = await this.props.userList();
			this.setState({ accounts });
		}
		let menu = menuIndex.find(m=>(location.pathname.includes(m.index)));

		this.props.appTitleUpdate((menu)?menu:{});
		document.title = `${(menu && menu.title)?menu.title+' | ':''}BytePlus Console`;
	}

	async componentDidUpdate(prevProps) {
		const { organizationalUnits, location, userProfile } = this.props;
		let _menuIndex = Object.assign(menuIndex);
		_menuIndex.push({index:'/client/new', title:'New Client'});
		for(let ou of organizationalUnits){
			_menuIndex.push({index:'/client/'+ou.ID, title:ou.Name});
		}
		// const _ou = organizationalUnits.map(ou=>{_menuIndex.push({index:'/client/'+ou.ID, title:ou.Name})});

		if(location !== prevProps.location || JSON.stringify(organizationalUnits) !== JSON.stringify(prevProps.organizationalUnits)){
			if(userProfile && userProfile.user && userProfile.user.superadmin){
				let accounts = await this.props.userList();
				this.setState({ accounts });
				for(let ac of accounts){
					_menuIndex.push({index:'/organization/'+ac.organization, title:`Reseller ${ac.accountPrefix}`});
				}
			}
			let menu = _menuIndex.find(m=>(location.pathname === m.index));
			await this.props.appTitleUpdate((menu)?menu:{});
			document.title = `${(menu && menu.title)?menu.title+' | ':''}BytePlus Console`;
		}
  }

	toggleUserModal = () => {
		const { userModal } = this.state;
		this.setState({ userModal: !userModal });
	}

	onLogout() {
		this.props.userLogout();
		this.props.history.push('/login');
	}

	onGoUser() {
		this.props.history.push('/user');
	}

	toggleSideNav = () => {
		const { userNav } = this.props;
		this.props.userSideNav(userNav);
	}

	handleClick = e => {
		this.props.history.push(e.key);
	};

	onOpenChange = keys => {
		var { openKeys} = this.state;
		const rootSubmenuKeys = ['Clients', 'Resellers'];
		const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			this.setState({ openKeys: keys });
		} else {
			this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
		}
	};

	render() {
		const { organizationalUnits, userProfile, location } = this.props;
		let { openKeys, accounts } = this.state;
		const classSideNav = (this.props.userNav) ? 'sideNavWrapper sideNavWrapperForce' : 'sideNavWrapper';
		const { SubMenu } = Menu;

		let indexPath = menuIndex.find(e=>(e.index === location.pathname))?location.pathname:'/';
		let defaultSubMenu =  menuIndex.find(e=>(e.index === location.pathname))?menuIndex.find(e=>(e.index === location.pathname))['submenu']:'Dashboard';

		if (openKeys.length === 0) {
			openKeys = [defaultSubMenu];
		}

		// const { loading } = this.state;
		const _ou = organizationalUnits.filter(o=>o.ParentID === userProfile.user.organization);

		if (this.props.userAuthenticated && userProfile.user) {
			return (
				<div>
				<div className={classSideNav}>
					<h1>
						<div className={`logoHolder ${!this.props.userNav && `fullIcon` }`}>{this.props.userNav && <img src={userImgMin} alt="App Logo" />}</div>
					</h1>
					<ul className="navHolder">
						{
							<Menu
								onClick={this.handleClick}
								defaultOpenKeys={['Clients', 'Resellers']}
								defaultSelectedKeys={[indexPath]}
								onOpenChange={this.onOpenChange}
								mode="inline"
								inlineCollapsed={this.props.userNav}
								selectedKeys={[location.pathname]}
								>
								<Menu.Item key="/" icon={<ApartmentOutlined />}>Organisation</Menu.Item>
								<SubMenu key="Clients" icon={<ProductOutlined />} title="Clients">
									{_ou && _ou.map((ou,i)=>
										<Menu.Item key={`/client/${ou.ID}`}>{ou.Name}</Menu.Item>
									)}
									<Menu.Item key={`/client/new`}>+ Create New</Menu.Item>
								</SubMenu>
								{userProfile.user.superadmin &&

									<SubMenu key="Resellers" icon={<AppstoreAddOutlined />} title="Resellers">
										{accounts && accounts.map((account,i)=>
											<Menu.Item key={`/organization/${account.organization}`}>{account.accountPrefix}</Menu.Item>
										)}
										<Menu.Item key="/organization/new"> + Create New</Menu.Item>
									</SubMenu>

								}
							</Menu>

						}
					</ul>

					<div className="user">
						<Tooltip title="Account Management">
							<Button type="link" style={{padding:0}} onClick={() => this.onGoUser()}><img className={this.props.userNav?`smallIcon`:`largeIcon`}src={avatar} alt="avatar" /></Button>
						</Tooltip>
						{!this.props.userNav &&
						<div className="label">
							<div className="name">
							{userProfile.user.name || userProfile.user.email}
							</div>
						</div>
						}
						<Tooltip title="Logout">
							<Button type="link" onClick={() => this.onLogout()}><LogoutOutlined /></Button>
						</Tooltip>
						{<span className="version">{`version ${version}`}</span>}
					</div>
				</div>
				<FloatButton onClick={this.toggleSideNav} className="floatBtn" icon={this.props.userNav?<MenuUnfoldOutlined />:<MenuFoldOutlined />} />
				</div>
			);
		}

		return (<div>&nbsp;</div>);
	}

}
//<span className="logout"><a onClick={() => this.onLogout()}><LogoutOutlined style={{ marginRight: '10px' }} />Logout</a></span>

const mapStateToProps = state => ({
	router: state.router,
	userAuthenticated: state.user.userAuthenticated,
	userProfile: state.user.userProfile,
	userNav: state.user.userNav,
	organizationalUnits: state.byteplus.organizationalUnits
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			userLogout,
			userSideNav,
			appTitleUpdate,
			userList
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav));
