import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Form, Input, Button, Alert, message, Select } from 'antd';
import Ink from 'react-ink';

import {
	userList,
	userInsert,
	userUpdate
} from '../../../modules/user';

 import { UserOutlined ,UserAddOutlined ,MailOutlined,LockOutlined,ArrowRightOutlined,ToolOutlined } from '@ant-design/icons';

const { Option } = Select;
const FormItem = Form.Item;

class AddUserModal extends Component {
	formRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			message: null
		}
	}

	handleSubmit = (values) => {
		this.setState({ loading: true });
		if (!this.props.data && values.password.length < 6) {
			this.props.form.setFields({
				password: {
					value: values.password,
					errors: [new Error('Password is too short (min character length is 6)')],
				}
			});
			this.setState({ loading: false });
		} else {
			if (!this.props.data) {
				this.props.userInsert(values).then(
					(res) => {
						message.success('User successfully added!');
						if (this.formRef.current) { this.formRef.current.resetFields(); }
						this.setState({ loading: false, message: null }, ()=>{
							this.onClose();
						});
					},
					(err) => {
						this.setState({ loading: false, message: `Can't get through. Please make sure email is unique or contact the administrator.` });
					}
				);
			} else {
				if(values.password === "" || values.confirmpassword === "" ){
					delete values.password;
					delete values.confirmpassword;
				}
				this.props.userUpdate(values, this.props.data.id).then(
					(res) => {
						message.success('User successfully updated!');
						if (this.formRef.current) { this.formRef.current.resetFields(); }
						this.setState({ loading: false, message: null }, ()=>{
							this.onClose();
						});
					},
					(err) => {
						this.setState({ loading: false, message: `Can't get through. Please make sure email is unique or contact the administrator.` });
					}
				);
			}

		}
	}

	handleConfirmBlur = (e) => {
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	}

	compareToFirstPassword = (rule, value, callback) => {

		const form = this.formRef.current;

		if (value && value.length !== 0 && value.length < 6) {
			callback('Must be at least 6 characters long');
		}
		else if (value && value !== form.getFieldValue('password')) {
			callback('Two passwords that you enter is inconsistent!');
		} else {
			callback();
		}
	}

	validateToNextPassword = (rule, value, callback) => {

		const form = this.formRef.current;

		if (value && value.length !== 0 && value.length < 6) {
			callback('Must be at least 6 characters long');
		}
		else if (value && this.state.confirmDirty) {
			form.validateFields(['confirmpassword'], { force: true });
		}
		callback();
	}


	onClose() {
		this.setState({
			message: null
		});
		if (this.formRef.current) { this.formRef.current.resetFields(); }
		this.props.toggleAddUserModal();
	}

	render() {
		const { loading, message } = this.state;
		const { userProfile } = this.props;
		let userRole = userProfile.user.role;

		const formItemLayout = {
			labelCol: { span: 8 },
			wrapperCol: { span: 14 },
		};

		const initialValues = {
			name: (this.props.data && this.props.data.name) ? this.props.data.name : "",
			email: (this.props.data && this.props.data.email) ? this.props.data.email : "",
			password: "",
			confirmpassword: "",
			role: (this.props.data && this.props.data.role) ? this.props.data.role : undefined,
			status: (this.props.data && this.props.data.status) ? this.props.data.status : "active",
		};
		if (this.formRef.current && !loading) {
			this.formRef.current.setFieldsValue(initialValues);
		}

		return (
			<Modal
				title={(this.props.data) ? <span><UserOutlined /> User Details</span> : <span><UserAddOutlined /> Add User</span>}
				visible={this.props.show}
				onCancel={() => this.onClose()}
				footer={[
					<Button key="submit" form="myUserForm" type="primary" htmlType="submit" disabled={loading} ><Ink /><ArrowRightOutlined /> Submit</Button>,
					<Button key="back" onClick={() => this.onClose()} disabled={loading}>Cancel</Button>
				]}
				maskClosable={false}
				closable={(loading) ? false : true}
				>
				<Form className="userForm" ref={this.formRef} name="myUserForm" onFinish={this.handleSubmit} initialValues={initialValues} >
					{(message) ? <Alert message={message} type="error" style={{ marginBottom: '20px' }} showIcon /> : ""}
					<FormItem  {...formItemLayout} label="Contact Name" name="name" rules={[{ required: true, message: 'Please input contact name' }]}>
						<Input prefix={<UserOutlined placeholder="Contact Name" />} />
					</FormItem>
					<FormItem  {...formItemLayout} label="Email" name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' },
					{ required: true, message: 'Please input your email!' }]}>
						<Input autoComplete="new-password" prefix={<MailOutlined />}  disabled={true} placeholder="Email" />
					</FormItem>
					<FormItem  {...formItemLayout} label="Password" name="password" rules={[{ required: (!this.props.data), message: 'Please input your Password!' },
					{ validator: this.validateToNextPassword }]}>
						<Input autoComplete="new-password" prefix={<LockOutlined />} type="password" placeholder="Password" />
					</FormItem>
					<FormItem  {...formItemLayout} label="Confirm Password" name="confirmpassword" rules={[{ required: (!this.props.data), message: 'Please confirm your password!' },
					{ validator: this.compareToFirstPassword }]}>
						<Input prefix={<LockOutlined/>} type="password" placeholder="Confirm Password"  />
					</FormItem>
					<FormItem  {...formItemLayout} label="Role" name="role" rules={[{ required: true, message: 'Please input organization' }]}>
						<Select
							placeholder="Select a role"
							disabled={true}
							>
							<Option value="viewer"><UserOutlined/> Viewer</Option>
							{userRole === 'admin' &&
								<Option value="admin"><ToolOutlined /> Admin</Option>
							}
						</Select>
					</FormItem>
					<FormItem  {...formItemLayout} label="Status" disabled={true} name="status" rules={[{ required: true }]}>
						<Select disabled={true}>
							<Option value="active">Active</Option>
							<Option value="inactive">Inactive</Option>
						</Select>
					</FormItem>
				</Form>
			</Modal>
		);
	}
}


const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			userList,
			userInsert,
			userUpdate
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
