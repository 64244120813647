import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";

import LoginCard from './LoginCard';
import './login.css';

class Login extends Component {

	render() {
		return (
			<Animated animationIn="fadeIn">
				<div className="loginWrapper loginPage" style={{ paddingBottom: (this.props.play) ? '100px' : '20px' }}>
					<div className="fullIcon"></div>
					<LoginCard />
					<div className="disclaimer">BytePlus Console @ 2024 All Rights Reserved.</div>
				</div>
			</Animated>
		);
	}
}

const mapStateToProps = state => ({
	userAuthenticated: state.user.userAuthenticated
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
