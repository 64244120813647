import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { Animated } from "react-animated-css";

import './Client.css';

import {
	listOrganizationalUnitsForParent, updateOrganizationalUnit, createOrganizationalUnit
} from '../../modules/byteplus';


const FormItem = Form.Item;

class Client extends Component {
	formRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading:true
		}
	}

	async componentDidMount() {
		this.setState({loading:true});
		if (this.formRef.current) {
			const initialValues = {
				name: "",
				description: "",
			};
			this.formRef.current.setFieldsValue(initialValues);
		}
		this.setState({loading:false});
	}

	async componentDidUpdate(prevProps) {


	}

	handleSubmit = async (values) => {
		const { userProfile } = this.props;
		this.setState({ loading: true });
		let data = {
				"ParentId": userProfile.user.organization,
		    "Name": values.name,
		    "Description": values.description
		};
		let result = await this.props.createOrganizationalUnit(data);
		if(result.Result && result.Result.ID){
			message.success("Create Client Success");
			await this.props.listOrganizationalUnitsForParent({"ParentId":userProfile.user.organization});
			this.onGoClient(result.Result.ID);
		}else{
			this.setState({ loading: false });
		}
	}




	vaildateFormUrl = (rule, value, callback) => {
		callback();
	}

	onNameChange(){
		// const form = this.formRef.current;
		// let name =  form.getFieldValue('name');
		// this.formRef.current.setFieldsValue({name: name.toUpperCase().replace(/[^A-Z0-9]/gmi, "").replace(/\s+/g, "")});
	}


	onDescriptionChange(){
		// const form = this.formRef.current;
		// let description =  form.getFieldValue('description');
		// this.formRef.current.setFieldsValue({name: name.toUpperCase().replace(/[^A-Z0-9]/gmi, "").replace(/\s+/g, "")});
	}

	onGoClient(e) {
		this.props.history.push(`/client/${e}`);
	}


	render() {
		// const { organizationalUnits, userProfile } = this.props;

		const { loading } = this.state;
		// const data = organizationalUnits.find(o=>o.ParentID === userProfile.user.organization && o.ID === id);

		const initialValues = {
			name: "",
			description: "",
		};

		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};


		return (
			<Animated animationIn="fadeIn">
				<div className="mainClientContentWrapper" style={{ paddingBottom: (this.props.play) ? '100px' : '20px' }}>
					<Row>
						<Col md={24} lg={24} xl={24} xxl={24}>
								<Row>
									 <Col span={24}>
										<Form ref={this.formRef} name="clientForm" onFinish={this.handleSubmit} initialValues={initialValues} >
											<FormItem {...formItemLayout} label="Name" name="name" rules={[{ validator: this.vaildateFormUrl},{ required: true, message: 'Please input client name' }]}>
												<Input onChange={this.onNameChange.bind(this)} disabled={false} placeholder="Client Name" />
											</FormItem>
											<FormItem {...formItemLayout} label="Description" name="description">
												<Input onChange={this.onDescriptionChange.bind(this)} disabled={false} placeholder="Description" />
											</FormItem>
										</Form>
									</Col>
									</Row>
									<Row>
									<Col span={12}>
									</Col>
									<Col span={12}>
										<Button key="submit" form="clientForm" type="primary" htmlType="submit" disabled={loading} >Create Client</Button>
									</Col>
								</Row>
						</Col>
					</Row>
				</div>
			</Animated>
		);
	}


}

const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile,
	organizationalUnits: state.byteplus.organizationalUnits
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			listOrganizationalUnitsForParent,
			createOrganizationalUnit,
			updateOrganizationalUnit
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Client);
