import sign_1 from './sign';
import fetch_1 from "./fetch";
import utils_1 from "./utils";
import sts2 from "./sts2";

const form_data_1 = require("form-data");
const querystring_1 = require("querystring");
const defaultOptions = (0, utils_1.getDefaultOption)();
const defaultPolicy = {
    Statement: [
        {
            Effect: "Allow",
            Action: ["*"],
            Resource: ["*"],
        },
    ],
};
const defaultExpire = 60 * 60 * 1000;
class Service {
    constructor(options) {
        this.setAccessKeyId = (accessKeyId) => {
            this.options.accessKeyId = accessKeyId;
        };
        this.setSecretKey = (secretKey) => {
            this.options.secretKey = secretKey;
        };
        this.setSessionToken = (sessionToken) => {
            this.options.sessionToken = sessionToken;
        };
        this.setRegion = (region) => {
            this.options.region = region;
        };
        this.setHost = (host) => {
            this.options.host = host;
        };
        this.getSessionToken = () => this.options.sessionToken;
        this.getAccessKeyId = () => this.options.accessKeyId;
        this.getSecretKey = () => this.options.secretKey;
        this.options = Object.assign(Object.assign({}, defaultOptions), options);
    }
    /**
     * create json api
     * @param Action OpenAPI Action
     * @param createParams.Version OpenAPI Version. If not provide, will use service defaultVersion.
     * @param createParams.method http method default is POST. You can also use 'PUT'
     * @param createParams.contentType body content type. support: json urlencode form-data. default is json.
     */
    createJSONAPI(Action, createParams) {
        return this.createAPI(Action, Object.assign({ method: "POST", contentType: "json" }, createParams));
    }
    /**
     * create urlencode api
     * @param Action OpenAPI Action
     * @param createParams.Version OpenAPI Version. If not provide, will use service defaultVersion.
     * @param createParams.method http method default is POST. You can also use 'PUT'
     * @param createParams.contentType body content type. support: json urlencode form-data. default is urlencode.
     */
    createUrlEncodeAPI(Action, createParams) {
        return this.createAPI(Action, Object.assign({ method: "POST", contentType: "urlencode" }, createParams));
    }
    /**
     * create form-data api
     * @param Action OpenAPI Action
     * @param createParams.Version OpenAPI Version. If not provide, will use service defaultVersion.
     * @param createParams.method http method default is POST. You can also use 'PUT'
     * @param createParams.contentType body content type. support: json urlencode form-data. default is form-data.
     */
    createFormDataAPI(Action, createParams) {
        return this.createAPI(Action, Object.assign({ method: "POST", contentType: "form-data" }, createParams));
    }
    /**
     * create api function
     * @param Action OpenAPI Action
     * @param createParams.Version OpenAPI Version. If not provide, will use service defaultVersion.
     * @param createParams.method http method like GET POST PUT
     * @param createParams.contentType body content type. support: json urlencode form-data. default is urlencode.
     */
    createAPI(Action, createParams) {
        const { Version, method = "GET", contentType = "urlencode", queryKeys = [], } = createParams || {};
        return (requestData, params, options) => {
            var _a;
            const requestParams = Object.assign(Object.assign({}, params), { method,
                Action,
                Version });
            if (method === "GET") {
                requestParams.query = requestData;
            }
            else {
                requestParams.query = Object.assign(Object.assign({}, queryKeys.reduce((res, key) => {
                    return requestData[key] !== undefined ? Object.assign(Object.assign({}, res), { [key]: requestData[key] }) : res;
                }, {})), ((_a = params === null || params === void 0 ? void 0 : params.query) !== null && _a !== void 0 ? _a : {}));
                switch (contentType) {
                    case "json": {
                        requestParams.headers = Object.assign(Object.assign({}, requestParams.headers), { "content-type": "application/json; charset=utf-8" });
                        requestParams.data = requestData;
                        break;
                    }
                    case "urlencode": {
                        const body = new URLSearchParams();
                        Object.keys(requestData)
                            .filter((key) => {
                            const val = requestData[key];
                            return val !== null && val !== undefined;
                        })
                            .forEach((key) => {
                            body.append(key, String(requestData[key]));
                        });
                        requestParams.data = body;
                        break;
                    }
                    case "form-data": {
                        const body = new form_data_1();
                        Object.keys(requestData).forEach((key) => {
                            body.append(key, requestData[key]);
                        });
                        requestParams.data = body;
                        const formDataHeaders = body.getHeaders() || {};
                        requestParams.headers || (requestParams.headers = {});
                        Object.keys(formDataHeaders).forEach((key) => {
                            requestParams.headers[key] = formDataHeaders[key];
                        });
                        break;
                    }
                    default: {
                        throw new Error(`contentType ${contentType} is not support`);
                    }
                }
            }
            return this.fetchOpenAPI(requestParams, options);
        };
    }
    fetchOpenAPI(params, options) {
        const realOptions = Object.assign(Object.assign({}, this.options), options);
        const requestInit = Object.assign(Object.assign({ pathname: "/" }, params), { params: Object.assign(Object.assign({}, params.query), { Action: params.Action, Version: params.Version || realOptions.defaultVersion }), region: realOptions.region || defaultOptions.region, method: params.method || "GET" });
        if (requestInit.data) {
            requestInit.body = requestInit.data;
        }
        // normalize query
        for (const [key, val] of Object.entries(requestInit.params)) {
            if (val === undefined || val === null) {
                requestInit.params[key] = "";
            }
        }
        const signer = new sign_1(requestInit, realOptions.serviceName);
        const { accessKeyId, secretKey, sessionToken } = realOptions;
        if (!accessKeyId || !secretKey) {
            throw new Error(`accessKeyId and secretKey is necessary`);
        }
        signer.addAuthorization({ accessKeyId, secretKey, sessionToken });
        let uri = `${realOptions.protocol || defaultOptions.protocol}//${realOptions.host || defaultOptions.host}${requestInit.pathname}`;
        const queryString = querystring_1.stringify(requestInit.params);
        if (queryString)
            uri += "?" + queryString;
        return (0, fetch_1)(uri, Object.assign(Object.assign({}, requestInit), { params: undefined }));
    }
    /**
     * get temporary ak sk
     * @param  {Policy|number} inlinePolicy? permission policy
     * @param  {number} expire? expires in milliseconds
     * @returns {SecurityToken2} object containing temporary ak/sk
     */
    signSts2(inlinePolicy, expire) {
        if (!inlinePolicy)
            inlinePolicy = defaultPolicy;
        if (typeof inlinePolicy === "number") {
            expire = inlinePolicy;
            inlinePolicy = defaultPolicy;
        }
        if (!expire)
            expire = defaultExpire;
        if (typeof expire !== "number") {
            throw new Error("SignSts2 second parameter must be a number");
        }
        const now = Date.now();
        const CurrentTime = new Date(now).toISOString();
        const timeInMilles = now + expire;
        const timeInSeconds = parseInt((timeInMilles / 1000).toFixed(0));
        const ExpiredTime = new Date(timeInMilles).toISOString();
        const { AccessKeyId, SecretAccessKey } = sts2.CreateTempAKSK();
        const sts = { AccessKeyId, SecretAccessKey };
        const innerToken = sts2.CreateInnerToken(this.options, sts, inlinePolicy, timeInSeconds);
        const SessionToken = "STS2" + sts2.base64(JSON.stringify(innerToken));
        return {
            CurrentTime,
            ExpiredTime,
            SessionToken,
            AccessKeyId,
            SecretAccessKey,
        };
    }
}
export default Service;

