import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, message, Spin, Card, Table, Popconfirm, Select, Tag } from 'antd';
import { Animated } from "react-animated-css";
import { EditOutlined, DeleteOutlined, TeamOutlined, UserAddOutlined, LinkOutlined, DollarOutlined } from '@ant-design/icons';
import moment from 'moment';

//import { UserAddOutlined, AppstoreAddOutlined, AppstoreOutlined, AuditOutlined, EditOutlined, DeleteOutlined, UserOutlined, SolutionOutlined, ToolOutlined, DollarOutlined, TeamOutlined, PlusOutlined, DeploymentUnitOutlined } from '@ant-design/icons';

import './Client.css';

import {
	listOrganizationalUnitsForParent, updateOrganizationalUnit
} from '../../modules/byteplus';


import {
	getAccountByOrgUnitId, listRoles, createServiceLinkedRole, listUsers, deleteUser, listBillDetailOwner
} from '../../modules/api';

import UserModal from './modal/UserModal';

const FormItem = Form.Item;

class Client extends Component {
	formRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading:true,
			addUserModal: false,
			selectedUser: null,
			BillPeriod: moment().format("YYYY-MM")
		}
	}

	async componentDidMount() {
		const { id } = this.props.match.params;
		await this.getAccountByOrgUnitId(id);
	}

	async componentDidUpdate(prevProps) {
		const { organizationalUnits } = this.props;
		const { id } = this.props.match.params;
		if(JSON.stringify(prevProps.organizationalUnits) !== JSON.stringify(organizationalUnits)){
			await this.getAccountByOrgUnitId(id);
		}
	}

	getAccountByOrgUnitId = async (id) => {
		const { organizationalUnits } = this.props;
		const { BillPeriod } = this.state;

		this.setState({loading:true});
		const result = await this.props.getAccountByOrgUnitId(id);
		this.setState({account:result});

		await this.props.listBillDetailOwner({"OwnerID":result.AccountId, BillPeriod});
		if(result.session){
			this.setState({Credentials:result.session.Credentials});
			await this.props.listUsers({PageSize:100, Limit: 100, ...result.session.Credentials});
			let roles = await this.props.listRoles({...result.session.Credentials});
			if(!roles.Result.RoleMetadata.find(e=>e.RoleName === "ServiceRoleForCDN")){
				await this.props.createServiceLinkedRole({"ServiceName": "CDN",...result.session.Credentials});
			}
			if(!roles.Result.RoleMetadata.find(e=>e.RoleName === "ServiceRoleForCDN")){
				await this.props.createServiceLinkedRole({"ServiceName": "certificate_service", ...result.session.Credentials});
			}
		}
		const data = organizationalUnits.find(o=>o.ID === id);
		const initialValues = {
			id: (data && data.ID) ? data.ID : "",
			name: (data && data.Name) ? data.Name : "",
			description: (data && data.Description) ? data.Description : "",
			creationTime: (data && data.CreatedTime) ? data.CreatedTime : "",
		};
		if (this.formRef.current && data) {
			this.formRef.current.setFieldsValue(initialValues);
		}
		this.setState({loading:false});
	}

	handleSubmit = async (values) => {
		const { userProfile } = this.props;
		this.setState({ loading: true });
		let data = {
		    "Name": values.name,
		    "Description": values.description,
		    "OrgUnitId": values.id
		};
		await this.props.updateOrganizationalUnit(data);
		message.success("Client Information Update Success");
		await this.props.listOrganizationalUnitsForParent({"ParentId":userProfile.user.organization});
		this.setState({ loading: false });
	}

  addUser = () => {
    this.setState({ selectedUser: null}, () => {
      this.toggleAddUserModal();
    });
  }

	onSelectUser(user = null) {
		this.setState({ selectedUser: user.UserName }, ()=>{
      this.toggleAddUserModal();
    });
	}

	onDeleteConfirm(UserName) {
		const { Credentials } = this.state;
    this.setState({ loading: true});
		this.props.deleteUser({UserName, ...Credentials}).then(
			async (res) => {
				this.setState({ selectedUser: null });
				await this.props.listUsers({PageSize:100, Limit: 100, ...Credentials});
				this.setState({ loading: false});
			},
			(err) => {
				this.setState({ loading: false, message: `Can't get through. Please try again later or contact the administrator.` });
			}
		);
	}

	onSelectMonth = async (BillPeriod) => {
		const { account } = this.state;
		await this.setState({ BillPeriod, loading: true });
		await this.props.listBillDetailOwner({"OwnerID":account.AccountId, BillPeriod});
		await this.setState({ BillPeriod, loading: false });
	}

	toggleAddUserModal = async () => {
		const { id } = this.props.match.params;
		const { addUserModal } = this.state;
		this.setState({ addUserModal: !addUserModal });
		if(addUserModal){
			await this.getAccountByOrgUnitId(id);
		}
	}

	render() {
		let { addUserModal, selectedUser, Credentials, BillPeriod, account } = this.state;
		const { organizationalUnits, organizationalUnitAccount, billDetailOwner, users } = this.props;
		const { id } = this.props.match.params;
		const data = organizationalUnits.find(o=>o.ID === id);
		const initialValues = {
			id: (data && data.ID) ? data.ID : "",
			name: (data && data.Name) ? data.Name : "",
			description: (data && data.Description) ? data.Description : "",
			creationTime: (data && data.CreatedTime) ? data.CreatedTime : "",
		};
		const { loading } = this.state;
		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};

		const sUser = (users && users.UserMetadata)?users.UserMetadata.find(user=>user.UserName === selectedUser):'';

		const columns = [
		{
			title: 'UserName',
			dataIndex: 'UserName',
			key: 'UserName',
			render: (text, data)  => <span>{text}</span>,
			sorter: (a, b) => a.UserName.localeCompare(b.UserName)
		},
		{
			title: 'DisplayName',
			dataIndex: 'DisplayName',
			key: 'DisplayName',
			render: (text, data)  => <span>{text}</span>,
			sorter: (a, b) => a.DisplayName.localeCompare(b.DisplayName)
		},
		{
			title: 'Description',
			dataIndex: 'Description',
			key: 'Description',
			sorter: (a, b) => a.Description.localeCompare(b.Description)
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, data) =>
				<span>
						<Button
							type="link"
							icon={<EditOutlined />}
							onClick={() => {
								this.onSelectUser(data);
							}}
						/>
						<Popconfirm
							title={`Delete User?`}
							onConfirm={() => {
								return this.onDeleteConfirm(data.UserName);
							}}
							okText="Yes"
							cancelText="No"
						>
							<Button icon={<DeleteOutlined />} type="link" />
						</Popconfirm>

						<Button
							type="link"
							icon={<LinkOutlined />}
							onClick={() => {
								window.open(`https://console.byteplus.com/auth/login/user/${organizationalUnitAccount.AccountId}`, '_blank')
							}}
						/>
				</span>
		}];

		const billingColumns = [
		{
			title: 'Product',
			dataIndex: 'Product',
			key: 'Product',
			render: (text, data)  => <span>{text}</span>,
			sorter: (a, b) => a.Product.localeCompare(b.Product)
		},
		{
			title: 'Description',
			dataIndex: 'ProductZh',
			key: 'ProductZh',
			render: (t, data)  => {
				return <span>
					{data.ProductZh !== data.Product && <div>{data.ProductZh}</div>}
					{data.Element !== "-" && <div>{data.Element}</div>}
					{data.Factor !== "-" && <div>{data.Factor}</div>}
					{data.InstanceName !== "-" && <div>{data.InstanceName}</div>}
				</span>
			},
			sorter: (a, b) => a.ProductZh.localeCompare(b.ProductZh)
		},
		{
			title: 'Zone',
			dataIndex: 'ZoneCode',
			key: 'ZoneCode',
			render: (text, data)  => <span>{text}</span>,
			sorter: (a, b) => a.ZoneCode.localeCompare(b.ZoneCode)
		},
		{
			title: 'Rate',
			dataIndex: 'Rate',
			key: 'Rate',
			render: (t, data)  => {
					let rate = "";
					rate = Number(data.Price)+" per "+data.PriceUnit.replace("时","Hour");
					if(data.Price === "-" && data.PriceInterval){
						rate = Number(data.PriceInterval.split(",")[0])+" per "+data.PriceUnit.replace("时","Hour");
					}
					return <span>{rate}</span>
			}
		},
		{
			title: 'Usage',
			dataIndex: 'Usage',
			key: 'Usage',
			render: (t, data)  => {
					let text = "";
					let rate = "";

					rate = Number(data.Count).toFixed(0)+" "+data.Unit;

					if(data.UseDurationUnit === "Second" && data.PriceUnit.includes("时")){
						text = (Number(data.UseDuration)/3600).toFixed(2) + "Hrs";
					}else{
						text = Number(data.Count).toFixed(3)+" "+data.PriceUnit.replace("/"," ");
					}
					if(data.PriceUnit.replace("时","Hour").includes("/Hour") && !rate.includes("/")){
						return <span>{rate}<br/>{text}</span>
					}else{
						return <span>{text}</span>
					}
			}
		},
		{
			title: 'Currency',
			dataIndex: 'Currency',
			key: 'Currency',
			render: (text, data)  => <span>{text}</span>,
			sorter: (a, b) => a.Currency.localeCompare(b.Currency)
		},
		{
			title: 'Bill Amount',
			dataIndex: 'OriginalBillAmount',
			key: 'OriginalBillAmount',
			render: (text, data)  => <span>{Number(text).toFixed(3)}</span>,
			sorter: (a, b) => a.OriginalBillAmount.localeCompare(b.OriginalBillAmount)
		}];

		let month_array = [moment().format("YYYY-MM")];
		let addMonth = 0;
		if(account && account.CreatedTime){
			while(month_array.indexOf(moment(account.CreatedTime).add(addMonth, 'months').format("YYYY-MM")) < 0){
				month_array.push(moment(account.CreatedTime).add(addMonth, 'months').format("YYYY-MM"));
				addMonth++;
			}
			month_array = month_array.sort((a,b)=>a.localeCompare(b));
		}
		let total_amount = 0;
		let billDetailOwnerData = billDetailOwner.map((e,index)=>({index,...e}));
		if(billDetailOwner){
			for(let b of billDetailOwner){
				if(b.OriginalBillAmount){
					total_amount += Number(b.OriginalBillAmount);
				}
			}
		}

		return (
			<Animated animationIn="fadeIn">
				<div className="mainClientContentWrapper" style={{ paddingBottom: (this.props.play) ? '100px' : '20px' }}>
					<Row>
						<Col md={24} lg={24} xl={24} xxl={24}>
								<Row>
									 <Col span={24}>
      			 				<Spin spinning={loading}>
										<Form ref={this.formRef} name="clientForm" onFinish={this.handleSubmit} initialValues={initialValues}>
											<FormItem {...formItemLayout} label="ID" name="id">
												<Input disabled={true} />
											</FormItem>
											<FormItem {...formItemLayout} label="Name" name="name" rules={[{ required: true, message: 'Please input client name' }]}>
												<Input disabled={false} placeholder="Client Name" />
											</FormItem>
											<FormItem {...formItemLayout} label="Description" name="description">
												<Input disabled={false} placeholder="Description" />
											</FormItem>
											<FormItem {...formItemLayout} label="Created At" name="creationTime">
												<Input disabled={true} />
											</FormItem>
											<FormItem {...formItemLayout} label="Account Status">
												<Input disabled={true} value={organizationalUnitAccount.status}/>
											</FormItem>
										</Form>
										</Spin>
									</Col>
									</Row>
									<Row>
									<Col span={12}>
									</Col>
									<Col span={12}>
										<Button key="submit" form="clientForm" type="primary" htmlType="submit" disabled={loading} >Update</Button>
									</Col>
								</Row>
								<Row>
								{organizationalUnitAccount && organizationalUnitAccount.status === "Active" &&
									<Card style={{width:'100%'}}>
			              <h3 className="title"><TeamOutlined /> User Management <Button type="link" icon={<LinkOutlined />} onClick={()=>{window.open(`https://console.byteplus.com/auth/login/user/${organizationalUnitAccount.AccountId}`, '_blank')}}>{`https://console.byteplus.com/auth/login/user/${organizationalUnitAccount.AccountId}`}</Button> <span className="extra"><Button type="link" icon={<UserAddOutlined />} onClick={()=>{this.addUser()}}>Add User</Button></span></h3>
			              <Table columns={columns} loading={loading} dataSource={(!loading && users && users.UserMetadata)?users.UserMetadata:[]} rowKey='Id' pagination={{pageSize:5}}/>
			            </Card>
								}
								{organizationalUnitAccount && organizationalUnitAccount.status === "Active" &&
									<Card style={{width:'100%'}}>
			              <h3 className="title"><DollarOutlined /> Billing Records <Tag>{`Total USD $${total_amount.toFixed(3)}`}</Tag><span className="extra">
										<Select
      defaultValue={BillPeriod}
      style={{ width: 120 }}
      onChange={this.onSelectMonth.bind(this)}
      options={month_array.map(e=>({value: e, label: e}))}
    />
		</span></h3>
			              <Table columns={billingColumns} loading={loading} dataSource={loading?[]:billDetailOwnerData.filter(e=>Number(e.OriginalBillAmount).toFixed(3) > 0).map(e=>{e.ZoneCode=(e.ZoneCode==="-")?e.Region==="Asia Pacific (Johor)"?"ap-southeast-1a":e.Region:e.ZoneCode; return e;})} rowKey='index' pagination={false}/>
			            </Card>
								}
								</Row>
						</Col>
					</Row>
				</div>
        <UserModal
          show={addUserModal}
          toggleAddUserModal={this.toggleAddUserModal.bind(this)}
          data={(selectedUser)?sUser:null}
					credentials={Credentials}
        />
			</Animated>
		);
	}


}

const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile,
	organizationalUnits: state.byteplus.organizationalUnits,
	organizationalUnitAccount: state.api.organizationalUnitAccount,
	billDetailOwner: state.api.billDetailOwner,
	users: state.api.users
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			listOrganizationalUnitsForParent,
			listBillDetailOwner,
			updateOrganizationalUnit,
			getAccountByOrgUnitId,
			listRoles,
			createServiceLinkedRole,
			listUsers,
			deleteUser
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Client);
