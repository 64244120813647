import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './topnav.css';

import userImgMin from '../../static/logo.png';

class TopNav extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fullscreen: false
		};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
  }


	render() {
		const { simple, userProfile, appTitle } = this.props;
		return (
			<div className="topNavWrapper">
				<div className="topNavTitle">
				{!(simple || !userProfile || !userProfile.user) &&
					<div>
					<div>
					{
						<div className={`logoHolder`}><img src={userImgMin} alt="App Logo" /></div>
					}{appTitle.title}</div>
						<h4 className="headerContentHolder">{}</h4>
					</div>
				}
				</div>
			</div>
		);
	}

}

const mapStateToProps = state => ({
	router: state.router,
	userNav: state.user.userNav,
	userProfile: state.user.userProfile,
	appTitle: state.user.appTitle
});



const mapDispatchToProps = dispatch =>
bindActionCreators(
	{

	},
	dispatch
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));
