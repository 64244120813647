import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import user from './user';
import api from './api';
import byteplus from './byteplus';

export default combineReducers({
	router: routerReducer,
	user,
	api,
	byteplus
});
