import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Button, Descriptions } from 'antd';
import { Animated } from "react-animated-css";

import 'moment-timezone';

import UserModal from './modal/UserModal';

import './usermanagement.css';

import { EditOutlined, UserOutlined } from '@ant-design/icons';

import {
	userList,
	userDelete
} from '../../modules/user';

class UserProfile extends Component {
	constructor(props){
		super(props);
		this.state = {
			addModal: false,
			selectedUser: null
		}
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
	}

	toggleAddModal = () => {
		const { addModal } = this.state;
		this.setState({ addModal: !addModal });
	}

	onSelectUser(user = null) {
		this.setState({ selectedUser: user.email });
	}

  editUser = () => {
    const { userProfile } = this.props;
		const { addModal } = this.state;
    this.setState({ selectedUser: userProfile.user.email, addModal: !addModal});
  }


  addUser = () => {
		const { addModal } = this.state;
    this.setState({ selectedUser: null, addModal: !addModal});
  }

	onDeleteConfirm(email) {
		const { selectedUser } = this.state;
		this.props.userDelete(email).then(
			(res) => {
				if (email === selectedUser) {
					this.setState({ selectedUser: null });
				}
			},
			(err) => {
				this.setState({ loading: false, message: `Can't get through. Please try again later or contact the administrator.` });
			}
		);
	}


	onGoTo(loc) {
		this.props.history.push(loc);
	}

	render() {
		const { addModal, selectedUser } = this.state;
    const { userProfile }  = this.props;

		const sUser = this.props.userData.find(user=>user.email === selectedUser) || userProfile.user;

		let label_style = {width:120, justifyContent:'right', fontWeight:'bold'};
		return (
			<Animated animationIn="fadeIn">
				<div className="mainContentWrapper">
          <Card>
          <h3 className="title"><UserOutlined /> User Information</h3>
          <Descriptions style={{padding:0}}>
						<Descriptions.Item labelStyle={label_style} span={3} label="Contact Name">{userProfile.user.name}</Descriptions.Item>
            <Descriptions.Item labelStyle={label_style} span={3} label="Email">{userProfile.user.email}</Descriptions.Item>
            <Descriptions.Item labelStyle={label_style} label="Role">{userProfile.user.role}</Descriptions.Item>
            <Descriptions.Item>{ <Button type="link" onClick={()=>{this.editUser()}}> <EditOutlined /> Edit </Button>}</Descriptions.Item>
          </Descriptions>
          </Card>
          <UserModal
            show={addModal}
            toggleAddUserModal={this.toggleAddModal.bind(this)}
            data={(selectedUser)?sUser:null}
          />

				</div>
			</Animated>
		);
	}
}

const mapStateToProps = state => ({
	router: state.router,
	userData: state.user.userData,
	userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
	{
		userList,
		userDelete
	},
	dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
